import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { redirect } from "react-router-dom";
import { App, URLOpenListenerEvent } from '@capacitor/app';

const AppUrlListener: React.FC<any> = () => {
  let navigate = useNavigate();
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      console.log("url: ", event.url);
      const slug = event.url.split('shop.tahko.com')[1];
      console.log("slug: ", slug);
      if (slug) {
        console.log("navigate to: ", slug.split("?")[0]);
        console.log("query to: ", slug.split("?")[1]);
        navigate({
          pathname: slug.split("?")[0],
          search: slug.split("?")[1] ? slug.split("?")[1] : "",
        });
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, []);

  return null;
};

export default AppUrlListener;