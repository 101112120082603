import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { Routes, Route, useLocation } from "react-router-dom";
import messages_fi from "./translations/fi.json";
import messages_en from "./translations/en.json";
import messages_ru from "./translations/ru.json";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import ActivityList from "./components/ActivityWidget/ActivityList";
import ActivityListDisplay from "./components/ActivityWidget/ActivityListDisplay";
import Slopes from "./components/SlopesAndLifts/Slopes";
import Lifts from "./components/SlopesAndLifts/Lifts";
import Events from "./components/Events/Events";
import Companies from "./components/Companies/Companies";
import IframedPage from "./components/IframedPage/IframedPage";
import Footer from "./components/Footer/Footer";
import PushNotificatoinInit from "./components/PushNotifications/PushNotifications";
import FirebaseAnalyticsInit from "./components/FirebaseAnalytics/FirebaseAnalytics";
import { Init as GeoLocationInit, SetLocation } from "./components/GeoLocation/GeoLocation"
import { default as AppVersionInit } from "./components/VersionHandler/CheckVersion";
import { Device } from '@capacitor/device';
import AppUrlListener from './components/AppUrlListener/AppUrlListener';

const messages: any = {
  fi: messages_fi,
  en: messages_en,
  ru: messages_ru,
};

function App() {
  
  const {search, pathname} = useLocation();
  console.log("search", search);
  console.log("pathname", pathname);

  const langId = new URLSearchParams(search).get("langId");
  const loginToken = new URLSearchParams(search).get("token");
  const email = new URLSearchParams(search).get("email");

  const [language, setLanguage] = useState("fi");

  Device.getLanguageCode().then((lang) => {
    
    if(lang.value === "fi" && language !== "fi"){
      setLanguage("fi")
    } else if(lang.value === "en" && language !== "en"){
      setLanguage("en");
    }
  });

  useEffect(() => {
    console.log("setLang", langId);
    langId && Object.keys(messages).includes(langId) && setLanguage(langId);
  }, [langId]);

  useEffect(() => {
    PushNotificatoinInit();
    FirebaseAnalyticsInit();
    GeoLocationInit();
    SetLocation();
  }, []);

  return (
    <IntlProvider
      locale={language}
      messages={messages[language]}
      defaultLocale="fi"
    >
      <AppVersionInit/>
      <ThemeProvider theme={theme}>
        <div>
          <Footer/>
          <AppUrlListener></AppUrlListener>
          <Routes>
            <Route path="/activities" element={<ActivityList />} />
            <Route path="/" element={<ActivityList />} />
            <Route path="/display" element={<ActivityListDisplay />} />
            <Route path="/slopes" element={<Slopes />} />
            <Route path="/lifts" element={<Lifts />} />
            <Route path="/events" element={<Events />} />
            <Route path="/companies" element={<Companies />} />
            <Route path="/live" element={<IframedPage url={"https://api2.tahko.com/livevideo/"} />} />
            <Route path="/weather" element={<IframedPage url={language === "fi" ? "https://api.tahko.com/forecabox.php" : "https://api.tahko.com/forecabox.php?lang=en"}/>} />     
            <Route path="/lifttickets" element={<IframedPage url={"https://api.tahko.com/tahkofy.php?url=https://rentle.store/tahko/l/tahkonrinteet/shop&client=TAHKOAPP"} />} />
            <Route path="/shop/services" element={<IframedPage url={(process.env.REACT_APP_MOBILESHOP_URL ? process.env.REACT_APP_MOBILESHOP_URL : "https://mobile.shop.tahko.com") + "/services" + search}></IframedPage>} />
            <Route path="/shop" element={<IframedPage url={(process.env.REACT_APP_MOBILESHOP_URL ? process.env.REACT_APP_MOBILESHOP_URL : "https://mobile.shop.tahko.com") + "/" + search}></IframedPage>} />

            {/* VOI POISTAA >*/}
            <Route path="/tahkolainen" element={<IframedPage url={(process.env.REACT_APP_MOBILESHOP_URL ? process.env.REACT_APP_MOBILESHOP_URL : "https://mobile.shop.tahko.com") + "/tahkolainen/card?langId=" + language} />} />
            <Route path="/login" element={<IframedPage url={(process.env.REACT_APP_MOBILESHOP_URL ? process.env.REACT_APP_MOBILESHOP_URL : "https://mobile.shop.tahko.com") + "/login?langId=" + language} />} />
            <Route path="/logintoken" element={<IframedPage url={(process.env.REACT_APP_MOBILESHOP_URL ? process.env.REACT_APP_MOBILESHOP_URL : "https://mobile.shop.tahko.com") + "/logintoken?token=" + loginToken + "&email=" + email + "&path=/reservations&langId=" + language} />} />
            <Route path="/reservations" element={
                <IframedPage url={(process.env.REACT_APP_MOBILESHOP_URL ? process.env.REACT_APP_MOBILESHOP_URL : "https://mobile.shop.tahko.com") + "/reservations?langId=" + language}></IframedPage>
            } />
            <Route path="/payment-success" element={<IframedPage url={(process.env.REACT_APP_MOBILESHOP_URL ? process.env.REACT_APP_MOBILESHOP_URL : "https://mobile.shop.tahko.com") + "/payment-success" + search} />} />
            <Route path="/payment-failed" element={<IframedPage url={(process.env.REACT_APP_MOBILESHOP_URL ? process.env.REACT_APP_MOBILESHOP_URL : "https://mobile.shop.tahko.com") + "/payment-failed" + search} />} />
            <Route path="/payment-otherwindow" element={<IframedPage url={(process.env.REACT_APP_MOBILESHOP_URL ? process.env.REACT_APP_MOBILESHOP_URL : "https://mobile.shop.tahko.com") + "/payment-otherwindow" + search} />} />
            {/* < VOI POISTAA */}
            <Route path="/changepassword" element={<IframedPage url={(process.env.REACT_APP_MOBILESHOP_URL ? process.env.REACT_APP_MOBILESHOP_URL : "https://mobile.shop.tahko.com") + "/changepassword" + search} />} />

            <Route path="*" element={<IframedPage url={(process.env.REACT_APP_MOBILESHOP_URL ? process.env.REACT_APP_MOBILESHOP_URL : "https://mobile.shop.tahko.com") + pathname + search}></IframedPage>} />
          </Routes>
          <div style={{zIndex: -9999, top: 0, position: "fixed", width: "100vw", height: "100vh", backgroundColor: theme.palette.background.default}}></div>

        </div>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
