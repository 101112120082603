import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { ActionPerformed as LocalNotificationActionPerformed, LocalNotifications, ScheduleOptions } from '@capacitor/local-notifications';
import { useAppState } from "../../Appstate";


const Init = () => {
    //const navigate = useNavigate();
    FirebaseMessaging.removeAllListeners();
    
    console.log('Initializing PushNotifications');
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    FirebaseMessaging.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        //PushNotifications.register();
        FirebaseMessaging.subscribeToTopic({ topic: 'all' });
      } else {
        // Show some error
      }
    });

    LocalNotifications.checkPermissions().then((permission) => {
      if(permission.display !== "granted")  {
        console.log("checkPermission", permission.display);
        LocalNotifications.requestPermissions().then((permission) => {
          console.log("requestPermission", permission.display);
        });
      }
    })

    // On success, we should be able to receive notifications
    FirebaseMessaging.addListener('tokenReceived', event => {
        console.log("token", event.token);
        useAppState.setState({pushNotificationToken: event.token});
        
        //alert('Push registration success, token: ' + token.value);
      }
    );

    // Show us the notification payload if the app is open on our device
    /*
    FirebaseMessaging.addListener('notificationReceived', event => {
      const notification = event.notification;
      let not: ScheduleOptions = {
        notifications: [{
          id: Date.now(),
          body: notification.body || "",
          title: notification.title || "",
          ongoing: false,
          extra: notification.data
        }]
      };
      const result = LocalNotifications.schedule(not)
      console.log("Notification", event);
    });
    */
    /*
    LocalNotifications.addListener('localNotificationActionPerformed',
      (notification: LocalNotificationActionPerformed) => {

        console.log("Action", notification);
        if(notification.notification.extra.external_url){
          console.log("urlFound", notification.notification.extra.external_url)
          try{
            new URL(notification.notification.extra.external_url);
            window.open(notification.notification.extra.external_url);
          } catch(e){
            //navigate(notification.data.external_url);
          }
        }
        if(notification.notification.extra.internal_url){
          console.log("InternalurlFound", notification.notification.extra.internal_url)
          try{
            window.location.assign(notification.notification.extra.internal_url);
            //NavigateTo();
            //navigate(notification.notification.data.internal_url);
          } catch(e){
            //navigate(notification.data.external_url);
          }
        }
      }
    );*/

    // Method called when tapping on a notification
    FirebaseMessaging.addListener('notificationActionPerformed', event => {
      const notification = event.notification;
        //alert('Push action performed: ' + JSON.stringify(notification));
        console.log("Action", notification);
        if(typeof notification.data === 'object' && notification.data !== null){
          if('external_url' in notification.data && notification.data.external_url && typeof notification.data.external_url === 'string'){
            console.log("urlFound", notification.data.external_url)
            try{
              new URL(notification.data.external_url);
              window.open(notification.data.external_url);
            } catch(e){
              //navigate(notification.data.external_url);
            }
          }
          if('internal_url' in notification.data && notification.data.internal_url && typeof notification.data.internal_url === 'string'){
            console.log("InternalurlFound", notification.data.internal_url)
            try{
              window.location.assign(notification.data.internal_url);
              //NavigateTo();
              //navigate(notification.notification.data.internal_url);
            } catch(e){
              //navigate(notification.data.external_url);
            }
          }
        }
    });
}

export default Init