import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'
import { theme } from "../../theme";
import { Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { CABIN_INFO } from "../../api/api";
import { useIntl } from "react-intl";
import { useNavigate } from 'react-router-dom';
import { LanguageId } from "../../utils";
import { ArrowForwardIos } from "@mui/icons-material";

function ActivityReservations(props : any) {
    const intl = useIntl();

    const items = props.activeReservations.map((reservation : any) => {
        return ({
            name: intl.formatMessage({ id: "yourReservation" }) + ":",
            description1: reservation.product,
            description2: new Date(reservation.startDate + " " + reservation.startTime).toLocaleString([], {year: "numeric",month: "numeric",day: "numeric"}) + " - " + new Date(reservation.endDate + " " + reservation.endTime).toLocaleString([], {year: "numeric",month: "numeric",day: "numeric"}),
            description3: "",
            link: "/reservations/" + reservation.id + "/" + reservation.productId,
            productId: reservation.productId
    })});

    

    return (
        <Carousel
        indicators={true}
        autoPlay={false}
        navButtonsAlwaysInvisible={items.length === 1}
        navButtonsAlwaysVisible={items.length > 1}>
            {
                items.map( (item: any, i: number) => <Item key={i + 1} item={item} /> )
            }
        </Carousel>
    )
}


function Item(props : any)
{
    const navigate = useNavigate();
    const intl = useIntl();
    const { data : cabinData } = useQuery(CABIN_INFO, {
        variables: {
            langId: LanguageId[intl.locale as never],
            cabinId: props.item.productId,
        },
        });
    console.log("cabinData", cabinData)
    return (
        <Paper
            sx={{
                display: "inline-flex",
                backgroundColor: theme.palette.primary.dark,
                height: 150
                }}>
            {cabinData?.www_cabinInformation?.mainPictureUrl && 
            <div style={{position: "relative"}}>
                <img src={cabinData?.www_cabinInformation?.mainPictureUrl} alt="" style={{position: "absolute", zIndex:999, margin: "auto", marginTop: "-40px", height: "300px", width:"100vw"}}/>
            </div>
            }
            <div onClick={() => navigate(props.item.link)} style={{paddingTop: "20px", width:"100%", textAlign: "center", position: "absolute", zIndex:9999,  color: "white", marginTop: 0}}>
                <div style={{margin: "auto", display: "inline-block", backgroundColor: theme.palette.primary.dark, padding: 20}}>

                    <div style={{float: "left"}}>
                        <Typography style={{marginBottom: "10px", textShadow: "0 0 15px #333333"}} variant="h4">{props.item.name}</Typography>
                        <Typography style={{textShadow: "0 0 10px #000000"}}>{props.item.description1}</Typography>
                        <Typography style={{textShadow: "0 0 10px #000000"}}>{props.item.description2}</Typography>
                        <Typography style={{textShadow: "0 0 10px #000000"}}>{props.item.description3}</Typography>
                    </div>
                    <div style={{float: "left", padding: "10px"}}>
                        <ArrowForwardIos style={{float: "left", position: "absolute", top: "50%"}}></ArrowForwardIos>
                    </div>
                </div>
               
            </div>
            
        </Paper>
    )
}

export default ActivityReservations