import React, { useState, useEffect, useRef } from 'react';
import IframeResizer from 'iframe-resizer-react'
import { useNavigate } from 'react-router-dom';
import { useAppState } from "../../Appstate";
import LoginIcon from '@mui/icons-material/Login';
import Avatar from '@mui/material/Avatar';
import { SetLocation } from "../GeoLocation/GeoLocation"
import { atTahko } from "../../utils";

function LoginStatus() {
    interface RefObject {
        sendMessage: (message: String) => void,
        window: Window
    } 
    const navigate = useNavigate();
    const [iframeRND, setIframeRND] = useState(0);
    const [iframeLoad, setIframeLoad] = useState(true);
    const setInitLogin = useAppState((state) => state.setInitLogin);

    const setAppState = useAppState((state) => state.setAppState);
    const loggedIn = useAppState((state) => state.appState.accessToken ? true : false);
    const firstName = useAppState((state) => state.appState.customerInfo?.firstName || "");
    const lastName = useAppState((state) => state.appState.customerInfo?.lastName || "");


    const iframeRef = useRef<RefObject>(null)

    const checkLogin = () => {
        const message = {
            action: "checkLogin",
        }
        iframeRef?.current?.sendMessage(JSON.stringify(message));
        console.log("checklogin");
    }

    const setAppStateToShop = () => {
        const message = {
            action: "setAppStateToShop",
            data: useAppState.getState().appState
        }
        console.log("dataToShop", message.data);
        iframeRef?.current?.sendMessage(JSON.stringify(message));
    }

    const setLocationToShop = () => {
        const coordinates = useAppState.getState().coordinates;
        if(coordinates !== ""){
            const message = {
                action: "setLocationToShop",
                data: coordinates
            };

            if(message.data){
                console.log("dataToShop", message);
                iframeRef?.current?.sendMessage(JSON.stringify(message));
            }
        }
    }


    const setPushNotificationTokenToShop = () => {
        const token = useAppState.getState().pushNotificationToken;
        if(token !== ""){
            const message = {
                action: "setPushNotificationTokenToShop",
                data: useAppState.getState().pushNotificationToken
            }
            console.log("setPushNotificationTokenToShop", message.data);
            iframeRef?.current?.sendMessage(JSON.stringify(message));
        }
    }

    const clearAppStateToShop = () => {
        const message = {
            action: "clearAppStateToShop"
        }
        iframeRef?.current?.sendMessage(JSON.stringify(message));
    }

    const handleClick = () => {
        navigate("/login")
    };

    const refreshIframe = () => { 
        setIframeLoad(true)
        const random = Math.floor(Math.random() * 1000);
        setIframeRND(random);
    }

    const handleIframeLoad = () => {
        setIframeLoad(false)
    }
    

    useEffect(() => {
        refreshIframe();
    }, [window.location.href]);

    useEffect(() => {
        if(!iframeLoad){
            SetLocation();
            checkLogin();
            atTahko();
        }
    },[iframeLoad]);

    useEffect(() => {
        // Refresh Location every 60 seconds
        const interval = setInterval(() => {
            SetLocation();
            atTahko();
            setLocationToShop();
        }, 60000);

        // Communication <-> Shop
            window.addEventListener(
                "message",
                (event : any) => {
                    console.log("message")
                    const loggedIn = useAppState.getState().appState.accessToken ? true : false;
                    const initLogin = useAppState.getState().initLogin;
                    if(event.origin === (process.env.REACT_APP_MOBILESHOP_URL ? process.env.REACT_APP_MOBILESHOP_URL : "https://mobile.shop.tahko.com")){
                        console.log("from shop");
                        console.log("data", event.data);
                        console.log("loggedIn", loggedIn)
                        console.log("initLogin", initLogin)
                        if(event.data.action === "sendAppState"){
                            if(event.data.appState.accessToken && event.data.appState.accessToken !== ""){
                                console.log("Shop logged in");
                                setPushNotificationTokenToShop();
                                setLocationToShop();
                                setAppState(event.data.appState);
                                setInitLogin();
                            } else if(loggedIn === true){ //&& initLogin === true){
                                console.log("App logged in");
                                setAppStateToShop();
                                setLocationToShop();
                                setPushNotificationTokenToShop();
                                setInitLogin();
                            } else {
                                console.log("Log out");
                                setAppState("");
                                clearAppStateToShop();
                            }
                        } else if(event.data.action === "LogOutToShop"){
                            console.log("Log out");
                            clearAppStateToShop();
                            setAppState("");
                            navigate("/");
                        } else if(event.data.action === "paytrailForm"){
                            console.log("APP_open_window");
                            
         
                            window.location.replace((process.env.REACT_APP_API2_URL ? process.env.REACT_APP_API2_URL : "https://api2.tahko.com")  + "/payment/paytrailForm?paymentData=" +  btoa(JSON.stringify(event.data.paymentData)));
                            //Browser.open({ url: (process.env.REACT_APP_API2_URL ? process.env.REACT_APP_API2_URL : "https://api2.tahko.com")  + "/payment/paytrailForm?paymentData=" +  btoa(JSON.stringify(event.data.paymentData)) });
                        }
                    }                      
                   return ;
            }, false);

        return () => clearInterval(interval);
     }, []);

    return (<>
            {loggedIn ? <Avatar variant="rounded" style={{fontWeight: 600, fontSize: "12px", width: "25px", height: "25px", border: "1px solid white",backgroundColor: "white", color: "rgb(21, 31, 54)"}}>{firstName.charAt(0)}{lastName.charAt(0)}</Avatar> :
            <LoginIcon style={{width: "25px", height: "25px", "fontSize": "38px", stroke: "white", strokeWidth: 1, color: "white"}}/>}
            <IframeResizer    
                        id={"loginStatusIframe"}
                        forwardRef={iframeRef}
                        onClick={() => handleClick()}   
                        onLoad={handleIframeLoad}  
                        log  
                        width='25px'
                        height='25px'
                        style={{display: "none", opacity: (iframeLoad ? 0 : 1), transition: "opacity 0.3s", pointerEvents: "none", flexGrow: 1, border: "0px"}}
                        src={(process.env.REACT_APP_MOBILESHOP_URL ? process.env.REACT_APP_MOBILESHOP_URL : "https://mobile.shop.tahko.com") + "/loginstatus?random=" + iframeRND} />
        </>)
}

export default LoginStatus