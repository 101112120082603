import { gql } from "@apollo/client";

export const ACTIVITY_INFO = gql`
  query activityInfo {
    www_activityInfo {
        snow
        golf_old
        golf_new
        iceparking
        icewarning
        snowing
        temperature
        temperature_updated
        slopeCount
        slopesOpen
        liftCount
        liftsOpen
        skitracks
        skitracks_open
        snowmobileRouteLength
    }
  }
`;

export const TAHKOCOM_EVENTS = gql`
  query tahkoComEvents($langId: String, $page: Int) {
    www_tahkoComEvents(langId: $langId, page: $page) {
      page
      totalPages
      events {
        title
        contentshort
        contentfull
        imagesrc
        dateStart
        dateEnd
        time
        price
        categories {id, title, parentId}
        webpage
        location
      }
    }
  }
`;

export const TAHKOCOM_COMPANIES = gql`
  query tahkoComCompanies($langId: String, $page: Int) {
    www_tahkoComCompanies(langId: $langId, page: $page) {
      title
      contentfull
      imagesrc
      contactInfo
      opening
      openToday
      isOpen
      categories {id, title, parentId}
      webpage
      location
    }
  }
`;

export const TAHKOCOM_TABLEBOOKING = gql`
  query tahkoComTableBooking($link: String) {
    www_tahkoComTableBooking(link: $link) {
      url
      merchantID
    }
  }
`;

export const SLOPES_AND_LIFTS = gql`
  query slopesandlifts {
    www_slopesandlifts {
      slopes {
        num
        nimi
        pituus
        info_f
        info_e
        info_r
        info_s
        info_d
        coords
        avataan
        suljetaan
        extra
        lumetus
        extra_c
        status
      }
      lifts {
        num
        nimi
        pituus
        info_f
        info_e
        info_r
        info_s
        info_d
        coords
        avataan
        suljetaan
        extra
        lumetus
        extra_c
        status
      }

    }
  }
`;

export const GET_ACTIVE_RESERVATIONS = gql`
  query getActiveReservations(
    $customerId: Int
  ) {
    www_activeReservations (customerId: $customerId) {
      id
      reservationItemId
      productId
      product
      startDate
      startTime
      endDate
      endTime
      amount
      price
      allowServiceOrder
    }
  }
`;

export const CABIN_INFO = gql`
  query GetCabinInfo(
    $cabinId: ID!
    $langId: Int!
    $starting: String
    $ending: String
    $persons: Int
  ) {
    www_cabinInformation(
      cabinId: $cabinId
      langId: $langId
      starting: $starting
      ending: $ending
      persons: $persons
    ) {
      id
      name
      address
      price
      rating
      description
      plusandminus
      housetype
      housetypeTxt
      distance
      area
      bedrooms
      roominformation
      mainPictureUrl
      secondaryPictureUrls
      totalArea
      gpsLat
      gpsLng
      petsAllowed
      maxPersons
      additionalPersons
      icons {
        icon_name
        icon_url
        icon_text
      }
      additional
      campaign
      producer
      accessories {
        id
        name
        value
      }
      usetimes
      reservationStartTime
      reservationEndTime
      bookingApi
      similarObjects
      caretakerId
    }
  }
`;
