import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import {
  CircularProgress,
  Divider,
} from "@mui/material";
import { decode } from "html-entities";
import { theme } from "../../theme";
import { TAHKOCOM_COMPANIES, TAHKOCOM_TABLEBOOKING } from "../../api/api";
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Chip from "@mui/material/Chip";
import { parseTimeString, occursWithinHour, isOpen } from "../../utils";
import { atTahko, getDistanceFromGps, getGPSfromGoogleLink } from "../../utils";
import { SetLocation } from "../GeoLocation/GeoLocation";
import { useAppState } from "../../Appstate";
import CompaniesHeader from "./Header";
import CompaniesList from "./CompaniesList";
import CompaniesMap from "./CompaniesMap";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const companygrid = {
  color: "white",
  textAlign: "center" as const,
  backgroundColor: theme.palette.primary.dark,
  margin: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
  width: "100%",
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
  
};

type Category = {
  id: number;
  title: string;
  parentId: number;
};

type CompanyType = {
  id: number;
  title: string;
  contentfull: string;
  contactInfo: string[];
  opening: string[];
  openToday: string;
  webpage: string;
  imagesrc: string;
  categories: Category[];
  location: string;
  isOpen: boolean;
  closetime: Date | null;
  opentime: Date | null;
  isOpenWithInHour: boolean;
};



const categoriesMap = [
  {name: "all", id: {"en": 0, "fi": 0}},
  {name: "seeanddo", id: {"en": 695, "fi": 162}},
  {name: "eatanddrink", id: {"en": 763, "fi": 180}},
  {name: "accommodation", id: {"en": 685, "fi": 140}}
];

export function Company(props: { company: CompanyType; showFullContentProp?: boolean }) {
  const { company } = props;
  const intl = useIntl();
  const [showFullContent, setShowFullContent] = useState(props.showFullContentProp ? true : false);
  const [tableBookingLink, setTableBookingLink] = useState<string | null>(null);
  const location = useAppState((state) => state.coordinates?.coords);


  const handleSetFullContent = () => {
    if (!showFullContent) {
      showFullContent ? setShowFullContent(false) : setShowFullContent(true);
    }
  };

  useQuery(TAHKOCOM_TABLEBOOKING, {
    variables: {
      link: company.webpage,
    },
    onCompleted: (data) => {
      setTableBookingLink(data.www_tahkoComTableBooking.url);
    },
    skip:
      company.webpage &&
      company.categories.find(
        (category) => category.parentId === 180 || category.id === 180
      )
        ? false
        : true,
  });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Grid
      onClick={() => handleSetFullContent()}
      style={companygrid}
      item
      xs={12}
      sm={12}
      md={12}
    >
      { company.imagesrc ? (
        ""
        //<img style={companyimage} src={company.imagesrc} alt={company.title} />
      ) : (
        ""
      )}
      <Typography pb={2} variant="h5">
        {company.title}
      </Typography>
      {company.isOpen ? (
        <Chip
          label={intl.formatMessage({ id: "open" })}
          color="success"
          sx={{ marginBottom: theme.spacing(2) }}
        />
      ) : company.isOpenWithInHour ? (
        <Chip
          label={intl.formatMessage({ id: "openwithinhour" })}
          color="warning"
          sx={{ marginBottom: theme.spacing(2) }}
        />
      ) : (
        ""
      )}
      <Typography pb={2}>{company.openToday}</Typography>
      {showFullContent && company.opening && (
        <>
          <Divider variant="middle" />
          {company.opening.map((op) => (
            <Typography pb={2}>{op}</Typography>
          ))}
          <Divider />
        </>
      )}

      <Typography
        style={{ display: showFullContent ? "block" : "none" }}
        pb={2}
      >
        {decode(company.contentfull)}
      </Typography>
      {company.contactInfo?.map((contactrow, i) => {
        // TO CHECK IF ROW IS PHONE NUMBER
        if (/^\+?\d+$/.test(contactrow?.replace(/\s/g, ""))) {
          return (
            <Typography
              style={{ display: showFullContent ? "block" : "none" }}
              key={i}
              pb={2}
            >
              <Button href={"tel:" + contactrow}>{contactrow}</Button>
            </Typography>
          );
        } else {
          return (
            <Typography
              style={{ display: showFullContent ? "block" : "none" }}
              key={i}
              pb={1}
            >
              {contactrow}
            </Typography>
          );
        }
      })}
      {company.webpage ? (
        <Typography
          style={{ display: showFullContent ? "block" : "none" }}
          pb={2}
        >
          <Button href={company.webpage} endIcon={<ArrowForwardIcon />}>
            {intl.formatMessage({ id: "companywebsite" })}
          </Button>
        </Typography>
      ) : (
        ""
      )}
      {company.location && (
        <>
        <Typography
          style={{ display: showFullContent ? "block" : "none" }}
          pb={2}
        >
          <Button href={company.location} endIcon={<LocationOnIcon />}>
            {intl.formatMessage({ id: "navigate" })}
          </Button>
        </Typography>
        
        </>
      )}
      {company.location && atTahko() && getGPSfromGoogleLink(company.location) && (
        <Typography>Etäisyys: {getDistanceFromGps(getGPSfromGoogleLink(company.location), location)}m</Typography>
      )}
      {tableBookingLink ? (
        <Typography
          style={{ display: showFullContent ? "block" : "none" }}
          pb={2}
        >
          <Button href={tableBookingLink}>
            {intl.formatMessage({ id: "bookatable" })} &#8594;
          </Button>
        </Typography>
      ) : (
        ""
      )}
      <InfoIcon
        style={{ display: showFullContent ? "none" : "inline-block" }}
      />
    </Grid>
  );
}

function Companies() {
  const { search : searchQuery } = useLocation();
  const queryStringValues = queryString.parse(searchQuery);

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [filterClosed, setFilterClosed] = useState(false);
  const [orderByDistance, setOrderByDistance] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [type, setType] = useState<string>(queryStringValues && typeof queryStringValues.type === "string" ? queryStringValues.type : "list");
  const [page, setPage] = useState(1);

  const [companies, setCompanies] = useState<Array<CompanyType>>([]);
  const intl = useIntl();
  const [search, setSearch] = useState("");
  const location = useAppState((state) => state.coordinates?.coords);
  const { loading, fetchMore } = useQuery(TAHKOCOM_COMPANIES, {
    variables: {
      langId: intl.locale,
      page: page,
    },
    onCompleted: (data) => {
      const tahkoComCompanies = data.www_tahkoComCompanies.map(
        (company: any) => {
          if (!company.openToday) {
            return { ...company, isOpen: false };
          }
          // company.openToday
          // Tänään: 9:00 - 17:00
          const regexpOpenHours =
            /^.*?:\s*([0-9]+:[0-9]+)\s*-\s*([0-9]+:[0-9]+)/;
          const openHoursMatch = company.openToday.match(regexpOpenHours);

          const opentime = parseTimeString(openHoursMatch[1]);
          const closetime = parseTimeString(openHoursMatch[2]);

          const isOpenWithInHour = occursWithinHour(opentime);
          const open = isOpen(opentime, closetime);

          return {
            ...company,
            isOpen: open,
            opentime: opentime,
            closetime: closetime,
            isOpenWithInHour: isOpenWithInHour,
          };
        }
      ).sort(() => (Math.random() > 0.5) ? 1 : -1);
      setCompanies([...companies, ...tahkoComCompanies]);
      if (data.www_tahkoComCompanies.length > 0) {
        setIsLoadingMore(true);
        setPage(page + 1);
      } else {
        setIsLoadingMore(false);
      }
    },
  });

  useEffect(() => {
    fetchMore({
      variables: {
        langId: intl.locale,
        page: page,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    // do every 60s
    const interval = setInterval(() => {
      atTahko();
      SetLocation();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        backgroundColor: theme.palette.primary.main,
        height: "100%",
        minHeight: "100vh",
        width: "100%",
        minWidth: "100vw",
      }}
    >
      {loading && page === 1 ? (
        <div
          style={{ display: "flex", justifyContent: "center", height: "100vh" }}
        >
          <CircularProgress style={{ color: "white" }} />
        </div>
      ) : (
        <Grid container>
          <CompaniesHeader categoriesMap={categoriesMap} setType={setType} setSelectedCategory={setSelectedCategory} setSearch={setSearch} setFilterClosed={setFilterClosed} setOrderByDistance={setOrderByDistance} type={type} selectedCategory={selectedCategory} filterClosed={filterClosed} orderByDistance={orderByDistance} isLoadingMore={isLoadingMore}></CompaniesHeader>
          {type === "list" ? 
            (<CompaniesList companies={companies} selectedCategory={selectedCategory} search={search} filterClosed={filterClosed} orderByDistance={orderByDistance} location={location}></CompaniesList>) :
            (<CompaniesMap companies={companies} selectedCategory={selectedCategory} search={search} filterClosed={filterClosed} orderByDistance={orderByDistance} location={location} categoriesMap={categoriesMap} loading={loading}></CompaniesMap>)
          }

        </Grid>
      )}
    </div>
  );
}

export default Companies;
