
import React, { useState, useEffect } from 'react';
import IframeResizer from 'iframe-resizer-react'
import { SafeArea } from 'capacitor-plugin-safe-area';

function IframedPage(props : {url : string }) {   
    const [statusBarHeight, setStatusBarHeight] = useState(0);

    useEffect(() => {
        SafeArea.getSafeAreaInsets().then(({ insets }) => {
            setStatusBarHeight(insets.top);
        });    
    },[]);
    
    return (
        <div style={{ height: `calc(100vh - 60px - ${statusBarHeight}px)`}}>
        
            <IframeResizer 
                id={"NavigationIframe"}
                resizeFrom={"child"}
                src={props.url}
                width='100%'
                height='100%'
                scrolling={true}
                style={{flexGrow: 1, border: "0px"}}
                inPageLinks={true}
                checkOrigin={false}
            />
        </div>
    );
}


export default IframedPage;