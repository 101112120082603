import { getDistance } from 'geolib';
import { useAppState } from "./Appstate";


export function parseTimeString(timeString: string) {
    return timeString ? new Date(new Date().setHours(parseInt(timeString.split(':')[0]), parseInt(timeString.split(':')[1]?.substring(0, 2)))) : null;
}
export function occursWithinHour(opentime: Date | null): boolean {
    return opentime ? (new Date().getTime() > (opentime?.getTime() - 1000 * 60 * 60) && (new Date().getTime() < opentime?.getTime())) : false
}

export function isOpen(opentime: Date | null, closetime: Date | null): boolean {
    if (opentime && closetime) {
        // IS OPEN
        return (new Date().getTime() > opentime?.getTime() && (new Date().getTime() < closetime?.getTime())) ||
            // CLOCKAROUND
            (opentime?.getTime() > closetime?.getTime() && (new Date().getTime() < closetime?.getTime() || new Date().getTime() > opentime?.getTime()))
    } else {
        return false;
    }

}

export function isSeason(season: "summer" | "winter") {
    const month = new Date().getMonth() + 1;
    const day = new Date().getDate();

    if ((month >= 5 && month <= 10) || (month === 4 && day >= 23 && month <= 10)){
        if (season === "summer") return true;
    } else {
        if (season === "winter") return true;
    }

    return false;
};

export function atTahko() {
    const tahkoCoordinates = {latitude: 63.28994449330883, longitude: 28.039915215550298}
    const maxdistance = 25000;
    if(process.env.REACT_APP_FORCE_TAHKO_LOCATION === "true"){
        return true;
    }
    if(useAppState.getState().coordinates) {
        const YourLatitude  = useAppState.getState().coordinates?.coords?.latitude
        const YourLongitude = useAppState.getState().coordinates?.coords?.longitude
        const timestamp = useAppState.getState().coordinates?.timestamp
        if(YourLatitude && YourLongitude && timestamp && (new Date().getTime() - Number(timestamp) * 1000) < 1000 * 60 * 60 * 24){
            const distance = getDistance(
                {latitude: YourLatitude, longitude: YourLongitude},
                tahkoCoordinates,
            )
            if(distance < maxdistance){
                return true;
            }
        }  
    } else {
        console.log("distanceNotInit");
    }
    
    return false;
}

export function getDistanceFromGps(startCoordinates: {latitude: string, longitude: string} | null, endCoordinates: {latitude: string, longitude: string} | null) {  
    if(startCoordinates && endCoordinates) {
        //const YourLatitude = useAppState((state) => state.coordinates?.coords?.latitude);
        //const YourLongitude = useAppState((state) => state.coordinates?.coords?.longitude);

        //const YourLatitude  = useAppState.getState().coordinates?.coords?.latitude
        //const YourLongitude = useAppState.getState().coordinates?.coords?.longitude
        
        return getDistance(
            startCoordinates,
            endCoordinates,
        )
    }
    return null;
} 

export function getGPSfromGoogleLink(link: string) {
    try{
        const match = link.split("/")[link.split("/").length -1].split(",");
        const regex = /\d+(.\d+)?/g;

        if (match && match.length === 2 && match[0].match(regex) && match[1].match(regex)){
        return {
            latitude: match[0],
            longitude: match[1],
        };
        }
        return null;

    } catch (e) {
        return null;
    }
  }



export enum LanguageId {
    fi = 1,
    en = 4,
    ru = 6,
  }