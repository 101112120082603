import React, { useMemo } from "react";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ApolloProvider } from "@apollo/client/react";
import { useAppState } from "./Appstate";

function ApiProvider() {
  const httpLink = useMemo(() => {
    return createHttpLink({
      uri: process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/graphql` : "https://api.tahko.com/graphql",
      headers: {
        "x-sessonid": useAppState.getState().appState?.sessionId,
        "x-customerid": useAppState.getState().appState?.customerInfo?.id?.toString() || "",
      },
    });
  }, [useAppState.getState().appState?.sessionId, useAppState.getState().appState?.customerInfo?.id]);

  const authLink = useMemo(() => {
    return setContext((_, { headers }) => {
      if (useAppState.getState().appState?.accessToken && useAppState.getState().appState?.accessToken !== "") {
        return {
          headers: {
            ...headers,
            authorization: useAppState.getState().appState?.accessToken
              ? `Bearer ${useAppState.getState().appState?.accessToken}`
              : "",
          },
        };
      }
      return {
        headers: {
          ...headers,
        },
      };
    });
  }, [useAppState.getState().appState?.accessToken]);

  const errorLink = useMemo(() => {
    return onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
      }
      if (networkError) {
      }
    });
  }, []);

  const client = useMemo(() => {
    return new ApolloClient({
      link: from([errorLink, authLink, httpLink]),
      cache: new InMemoryCache(),
      defaultOptions: {
        mutate: { errorPolicy: "ignore" },
      },
    });
  }, [errorLink, authLink, httpLink]);

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default ApiProvider;
