import React, { useState, useRef, useEffect } from 'react';
import { LatLngTuple } from 'leaflet';
import { MapContainer, useMapEvents, TileLayer, useMap, Marker, Popup, Tooltip } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import { getGPSfromGoogleLink } from '../../utils';
import Supercluster from 'supercluster';
import './map.css'
import { Company } from './Companies';



type Category = {
    id: number;
    title: string;
    parentId: number;
  };

  
type CompanyType = {
    id: number;
    title: string;
    contentfull: string;
    contactInfo: string[];
    opening: string[];
    openToday: string;
    webpage: string;
    imagesrc: string;
    categories: Category[];
    location: string;
    isOpen: boolean;
    closetime: Date | null;
    opentime: Date | null;
    isOpenWithInHour: boolean;
  };

  interface companyProps {
    company: CompanyType;
  }

function MapClusterComponent(props: {companies: CompanyType[], setClustersAndCompanies: any, loading: boolean,  selectedCategory: number, search: string, filterClosed: boolean}) {
    const mapRef = useMap();
    

    function update(){
        const b  = mapRef.getBounds();
        const bounds : [number, number, number, number, number, number] = [
            b.getSouthWest().lng,
            b.getSouthWest().lat,
            b.getNorthEast().lng,
            b.getNorthEast().lat,
            0,
            0
        ];
        const zoom = (mapRef.getZoom());

        const index = new Supercluster({radius: 40, maxZoom: 16});
        index.load(props.companies.map((company) => ({
            type: "Feature",
            properties: {cluster: false, company: company},
            geometry: {
                type: "Point",
                coordinates: [Number(getGPSfromGoogleLink(company?.location)?.longitude), Number(getGPSfromGoogleLink(company?.location)?.latitude)]
            }
        })));
        const clusters = index.getClusters(bounds, zoom);
        props.setClustersAndCompanies(clusters.map((cluster: any) => {
            if(cluster.properties.cluster){
                
            }
            return {
                cluster: cluster.properties.cluster,
                clusterCount: cluster.properties.point_count,
                ...cluster.properties.company,
                location: cluster.geometry.coordinates
            }
        }));
    }

    const map = useMapEvents({
        zoom: () => {
            update();
        },
        moveend: () => {
            update();
        },    
        load: () => {
            update();
        }

    });

    useEffect(() => {   
        update();
    },[props.loading, props.selectedCategory, props.search, props.filterClosed]);

    

    return null;
}

function MarkerComponent(props: {company: any, setShowInfo: any}) {
    const map = useMap();

    var clusterIcon = L.icon({
        iconUrl: "./cluster.svg",
        iconSize: [24, 32],
        iconAnchor: [4, 32]
    })

    var SeePylypyra = L.icon({
        iconUrl: "./see-icon.svg",
        iconSize: [24, 32],
        iconAnchor: [4, 32]
    })

    var BedPylypyra = L.icon({
        iconUrl: "./bed-icon.svg",
        iconSize: [24, 32],
        iconAnchor: [4, 32]
    })

    var FoodPylypyra = L.icon({
        iconUrl: "./food-icon.svg",
        iconSize: [24, 32],
        iconAnchor: [4, 32]
    })
    
    var pylypyra = L.icon({
        iconUrl: "./all-icon.svg",
        iconSize: [24, 32],
        iconAnchor: [4, 32]
    })

    const findCategoryIcon = function(company : any) {
        const findCategory = function(findcategory : any, company : CompanyType) {
            return company?.categories?.find((category: { parentId: any; id: any; }) =>
                category.parentId === findcategory ||
                category.id === findcategory
            )
        };
        if(company.cluster){
            return clusterIcon;
        }
        if(findCategory(162, company)){
            return SeePylypyra;
        } else if(findCategory(180, company)){
            return FoodPylypyra;
        } else if(findCategory(140, company)){
            return BedPylypyra
        } else {
            return pylypyra;
        }
    }

    const clickHandler = (e: any, company: any) => {
        if(company.cluster){
            map.flyTo([Number(company.location[1]), Number(company.location[0])], map.getZoom() + 1);
        } else{
            map.panTo([Number(company.location[1]), Number(company.location[0])]);
            props.setShowInfo(company);
        }

    };

    
    

    const {company} = props;
    return (
        <Marker eventHandlers={{click: (e) => clickHandler(e, company)}} icon={findCategoryIcon(company)} position={[Number(company.location[1]), Number(company.location[0])]}>
            {company?.cluster ? 
                <Tooltip direction="center" offset={[8, -21]} opacity={1} permanent>{company.clusterCount}</Tooltip> : ""
            }   
        </Marker>)
}

function CompaniesMap(props: {companies: CompanyType[], selectedCategory: number, search: string, filterClosed: boolean, orderByDistance: boolean, location: any | null, categoriesMap: any, loading: boolean}) {
    const {companies, selectedCategory, search, filterClosed, orderByDistance, location} = props;
    const [clustersAndCompanies, setClustersAndCompanies] = useState<CompanyType[]>([]);
    const [showInfo, setShowInfo] = useState<any>(null);
    

    const useOutsideClick = (callback : any) => {
        const ref : any = React.useRef();
      
        React.useEffect(() => {
          const handleClick = (event : any) => {
            if (ref.current && !ref.current?.contains(event.target)) {
                callback();
            }
          };
      
          document.addEventListener('click', handleClick, true);
      
          return () => {
            document.removeEventListener('click', handleClick, true);
          };
        }, [ref]);
      
        return ref;
      };

    const ref : any = useOutsideClick(() => setShowInfo(null));

    
    const position: LatLngTuple = [63.28994449330883, 28.039915215550298];

    const filteredCompanies = companies.filter((company) =>
            company.categories.find(
            (category) =>
                selectedCategory === 0 ||
                category.parentId === selectedCategory ||
                category.id === selectedCategory
            )
        )
        .filter(
            (company) =>
            search === "" ||
            company.title.toLowerCase().includes(search.toLowerCase())
        )
        .filter((company) => company.isOpen || !filterClosed)
        .filter((company) => company.location)
        .sort((ca: CompanyType, cb: CompanyType) => {
            return (
            Number(cb.isOpen) - Number(ca.isOpen) ||
            Number(cb.openToday !== null) - Number(ca.openToday !== null) ||
            Number(cb.isOpenWithInHour) - Number(ca.isOpenWithInHour)
            //Number(ca.opentime) - Number(cb.opentime)
            );
        });


    console.log("clustersAndCompanies", clustersAndCompanies);

    
 
    return (
        <div style={{zIndex: 9999, width: "100vw", height: "calc(100vh - 290px)"}} id="map">
            {showInfo && 
                <div ref={ref} style={{zIndex: 9999, position: "absolute", top: 320, width: "100%", textAlign: "center", padding: "10px"}}>
                    <span onClick={() => setShowInfo(null)} style={{color: "white", float: "right", margin: "20px"}}><b>X</b></span>
                    <Company company={showInfo} showFullContentProp={true}/> 
                </div>
            }
            <MapContainer style={{
                    height: "100%",
                    width: "100%",
			    }} 
                center={position} 
                zoom={13} 
                >
                <MapClusterComponent companies={filteredCompanies} setClustersAndCompanies={setClustersAndCompanies} loading={props.loading} selectedCategory={selectedCategory} search={search} filterClosed={filterClosed}/>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    updateWhenZooming={true}
                />
                {clustersAndCompanies.map((company: any, index: number) => {
                    if (company?.location) {
                        return (
                            <MarkerComponent key={company.title} company={company} setShowInfo={setShowInfo}/>
                        );
                    }
                })}
                
            </MapContainer>
        </div>
    );
}

export default CompaniesMap;