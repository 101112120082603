import { createTheme } from "@mui/material/styles";
declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    interface ThemeOptions {
        status?: {
            danger?: string;
        };

    }
}

export const theme = createTheme({
    palette: {
        background: {
            default: "#1E2D4E",
        },
        primary: {
            main: "#1E2D4E",
            light: "#B3C5E2"
        },
        secondary: {
            main: "#ff4040",
            dark: "#D02213"
        },
        warning: {
            main: "#DDBC62"
        },
        error: {
            main: "#D02213"
        }
    },
    typography: {
        fontFamily: 'U8,Helvetica,sans-serif',
        h1: {
            fontFamily: 'veljmies-sans-bold,Helvetica,sans-serif',
            fontWeight: 600,
            textTransform: 'uppercase',
        },
        h2: {
            fontFamily: 'veljmies-sans-bold,Helvetica,sans-serif',
            fontSize: '2.75rem',
            fontWeight: 600,
            textTransform: 'uppercase',
        },
        h4: {
            fontFamily: 'veljmies-sans-bold,Helvetica,sans-serif',
            fontSize: '1.25rem',
            fontWeight: 600,
            textTransform: 'uppercase',
        },
        h5: {
            fontFamily: 'veljmies-sans-bold,Helvetica,sans-serif',
            fontSize: '1.15rem',
            fontWeight: 600,
        },
        h6: {
            fontFamily: 'veljmies-sans-bold,Helvetica,sans-serif',
            fontSize: '1rem',
            fontWeight: 600,
        },
        caption: {
            fontFamily: 'veljmies-sans-bold,Helvetica,sans-serif',
            fontSize: '0.6rem',
            fontWeight: 300,
        },

    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    border: "1px solid white",
                    borderRadius: 4,
                    margin: 4,
                    color: "#FFFFFF"
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    border: "1px solid white",
                    borderRadius: 4,
                    input: { color: "white" },
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 4
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: "rgba(255,255,255,0.5)",
                    marginBottom: "16px"
                }
            }
        }
    },
});
