import { theme } from "../../theme";

import { getDistanceFromGps, getGPSfromGoogleLink } from "../../utils";

import { Company } from './Companies';

type Category = {
    id: number;
    title: string;
    parentId: number;
  };

  
type CompanyType = {
    id: number;
    title: string;
    contentfull: string;
    contactInfo: string[];
    opening: string[];
    openToday: string;
    webpage: string;
    imagesrc: string;
    categories: Category[];
    location: string;
    isOpen: boolean;
    closetime: Date | null;
    opentime: Date | null;
    isOpenWithInHour: boolean;
  };

  interface companyProps {
    company: CompanyType;
  }

  

function CompaniesList(props: {companies: CompanyType[], selectedCategory: number, search: string, filterClosed: boolean, orderByDistance: boolean, location: any | null}) {
    const {companies, selectedCategory, search, filterClosed, orderByDistance, location} = props;


    return (<>{companies
        .filter((company) =>
            company.categories.find(
            (category) =>
                selectedCategory === 0 ||
                category.parentId === selectedCategory ||
                category.id === selectedCategory
            )
        )
        .filter(
            (company) =>
            search === "" ||
            company.title.toLowerCase().includes(search.toLowerCase())
        )
        .filter((company) => company.isOpen || !filterClosed)
        .sort((ca: CompanyType, cb: CompanyType) => {
            return (
            Number(cb.isOpen) - Number(ca.isOpen) ||
            Number(cb.openToday !== null) - Number(ca.openToday !== null) ||
            Number(cb.isOpenWithInHour) - Number(ca.isOpenWithInHour)
            //Number(ca.opentime) - Number(cb.opentime)
            );
        })
        .sort((ca: CompanyType, cb: CompanyType) => { 
            if(orderByDistance) {
            const distanceA = ca.location ? getDistanceFromGps(getGPSfromGoogleLink(ca.location), location) : null; 
            const distanceB = cb.location ? getDistanceFromGps(getGPSfromGoogleLink(cb.location), location) : null; 

            if(distanceA === null){
                return 1;
            }
            else if(distanceB === null){
                return -1;
            }
            else if(distanceA === distanceB){
                return 0;
            }
            
            return distanceA < distanceB ? -1 : 1;    
            } else return 0;
        })
        .map((company: CompanyType, index: number) => (
            <Company company={company} key={index} />
    ))}</>)
}

export default CompaniesList;