import React, { ReactElement, ReactNode } from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { theme } from "../../theme";
import { ArrowForwardIos } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';

interface Props {
  title: string;
  metric?: string;
  unit?: string;
  icon: ReactNode;
  link?: string;
  half?: boolean
}

function ActivityCard({
  title,
  metric,
  icon,
  link,
  unit,
  half
}: Props): ReactElement {
  const navigate = useNavigate();

  const handleUrl = (url : string) => {
    try{
      new URL(url);
      window.location.replace(url);
    } catch(e){
      navigate(url);
    }

    return false;
  };

  return (
    <a onClick={() => handleUrl(link || "/")} style={{ textDecoration: "none", color: "#FFFFFF" }}>
      {half ? 
        <Box
          padding={theme.spacing(1.5)}
          sx={{
            backgroundColor: theme.palette.primary.dark,
            borderRadius: 1,
            color: "#FFFFFF",
            height: "100%",
          }}
          data-test-id="activity-card"
        >
          <Grid container direction="row">
            <Grid item xs={11}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={2}>
                      <Box sx={{ display: "flex", height: 20 }}>{icon}</Box>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography
                        variant="caption"
                        sx={{
                          lineBreak: "auto",
                          textTransform: "uppercase",
                          mt: theme.spacing(1),
                        }}
                      >
                        {title}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} maxHeight={23}>
                      {!metric ? (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={13}
                          sx={{
                            bgcolor: theme.palette.primary.main,
                            borderRadius: 1,
                            mt: 1,
                          }}
                        />
                      ) : (
                        <>
                          <Typography
                            display={"block"}
                            variant="caption"
                            sx={{
                              textTransform: "uppercase",
                              textAlign: "left",

                            }}
                          >
                            {metric}
                          </Typography>
                          <Typography
                          display={"block"}
                            variant="caption"
                            sx={{
                              textTransform: "uppercase",
                              textAlign: "left",
                              
                              fontSize: 6,
                            }}
                          >
                            {unit}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {link &&
              (<Grid item xs={1}>
                  <ArrowForwardIos sx={{ fontSize: 12 }} />
              </Grid>)
            }
          </Grid>
        </Box>
      : (
        <Box
          padding={theme.spacing(1.5)}
          sx={{
            backgroundColor: theme.palette.primary.dark,
            borderRadius: 1,
            color: "#FFFFFF",
            height: "100%",
          }}
          data-test-id="activity-card"
        >
          <Grid container direction="row">
            <Grid item xs={11}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={5}>
                      <Box sx={{ display: "flex", height: 45 }}>{icon}</Box>
                    </Grid>
                    <Grid item xs={6}>
                      {!metric ? (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={ 30 } 
                          sx={{
                            bgcolor: theme.palette.primary.main,
                            borderRadius: 1,
                            mt: 1,
                          }}
                        />
                      ) : (
                        <>
                          <Typography
                            variant={"h5"}
                            sx={{
                              textTransform: "uppercase",
                              textAlign: "left",
                              lineHeight: "24px",
                            }}
                          >
                            {metric}
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{
                              textTransform: "uppercase",
                              textAlign: "left",
                              lineHeight: "18px",
                              fontSize: 12,
                            }}
                          >
                            {unit}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid sx={{"display": "inline-block"}} item xs={12}>
                    <Typography
                      variant={"h6"}
                      sx={{
                        textTransform: "uppercase",
                        mt: theme.spacing(1),
                      }}
                    >
                      {title}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Box sx={{ pt: "25px" }}>
                {link && <ArrowForwardIos sx={{ fontSize: 12 }} />}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </a>
  );
}

export default ActivityCard;
