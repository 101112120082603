import React, { ReactElement, ReactNode } from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { theme } from "../../theme";

interface Props {
  title: string;
  metric?: string;
  unit?: string;
  icon: ReactNode;
  link?: string;
}

function ActivityCardDisplay({
  title,
  metric,
  icon,
  link,
  unit,
}: Props): ReactElement {
  return (
    <a href={link} style={{ textDecoration: "none", color: "#FFFFFF" }}>
      <Box
        padding={theme.spacing(4)}
        sx={{
          backgroundColor: theme.palette.primary.dark,
          borderRadius: 1,
          color: "#FFFFFF",
          height: "100%",
        }}
        data-test-id="activity-card"
      >

            <Grid container direction="column">
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={5}>
                    <Box sx={{ display: "flex" }}>{icon}</Box>
                  </Grid>
                  <Grid item xs={7}>
                    {!metric ? (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={30}
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          borderRadius: 1,
                          mt: 1,
                        }}
                      />
                    ) : (
                      <>
                        <Typography
                          variant="h1"
                          sx={{
                            textTransform: "uppercase",
                            textAlign: "left",
                            lineHeight: "130px",
                          }}
                        >
                          {metric}
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            textTransform: "uppercase",
                            textAlign: "left",
                            lineHeight: "18px",
                            fontSize: 30,
                          }}
                        >
                          {unit}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{marginTop: "20px"}}>
                  <Typography
                    variant="h3"
                    sx={{
                      textTransform: "uppercase",
                      mt: theme.spacing(1),
                    }}
                  >
                    {title}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
      </Box>
    </a>
  );
}

export default ActivityCardDisplay;
