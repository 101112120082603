import { useState } from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'
import { theme } from "../../theme";
import { Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { TAHKOCOM_EVENTS } from "../../api/api";
import { useIntl } from "react-intl";
import { useNavigate } from 'react-router-dom';
import { isSeason } from '../../utils';


type Category = {
    id: number;
    title: string;
    parentId: number;
  };
  
  type EventType = {
    title: string;
    contentshort: string;
    contentfull: string;
    imagesrc: string;
    dateStart: string;
    dateEnd: string;
    time: string;
    price: string;
    webpage: string;
    categories: Category[];
    location: string;
  };
  

function ActivityCarousel() {
    const [events, setEvents] = useState<Array<EventType>>([]);
    const [page, setPage] = useState(1);
    const intl = useIntl();
    useQuery(TAHKOCOM_EVENTS, {
        variables: {
          langId: intl.locale,
          page: page,
        },
        onCompleted: (data) => {
          setEvents([...events, ...data.www_tahkoComEvents.events]);
          if (
            data.www_tahkoComEvents.events.length > 0
          ) {
            setPage(page + 1);
          } else {
          }
        }
      });

    
    const inititems = [
        {
            name: "SEIKKAILE, SPORTTAA, NAUTI.",
            description1: "",
            description2: "",
            description3: "",
            link: "",
            videosrc: isSeason("summer") ? "https://files.tahko.com/TahkoKesa.mp4" : "https://files.tahko.com/TahkoTalvi.mp4"
        }
    ]

    const itemsFromEvents = events
        .sort(
            (a: any, b: any) =>
            new Date(a.dateStart).getTime() -
            new Date(b.dateStart).getTime()
        )
        .filter((event) => 
            (new Date(event.dateStart).getTime() >= new Date(new Date().setHours(0, 0, 0, 0)).getTime() && new Date(event.dateStart).getTime() <= new Date(new Date().setHours(23, 59, 0, 0)).getTime())
        )
        .map((eventitem : EventType) => ({"name": eventitem.title, "description1": eventitem.contentshort, "description2": "Tänään Tahkolla! "  + eventitem.time , "description3":  eventitem.price, "imagesrc": eventitem.imagesrc, "link": "/events"}) )
    const items = [...inititems, ...itemsFromEvents]

    

    return (
        <Carousel
        indicators={false}
        interval={10000}>
        
            {
                items.map( (item, i) => <Item key={i + 1} item={item} /> )
            }
        </Carousel>
    )
}


function Item(props : any)
{
    const navigate = useNavigate();

    return (
        <Paper
            sx={{
                display: "inline-flex",
                backgroundColor: theme.palette.primary.dark,
                height: 150
                }}>
            {props.item.videosrc && 
            <div style={{position: "relative"}}>
                <video playsInline controls={false} style={{position: "absolute", top: 0, zIndex:999, marginTop: "-40px", height: "300px", width:"100vw"}} autoPlay muted loop>
                    <source src={props.item.videosrc} type="video/mp4"/>
                </video>
            </div>
            }
            {props.item.imagesrc && 
            <div style={{position: "relative"}}>
                <img alt={""} src={props.item.imagesrc} style={{position: "absolute", zIndex:999, marginTop: "-40px", height: "300px", width:"100vw"}}>
        
                </img>
            </div>
            }
            <div onClick={() => navigate(props.item.link)} style={{paddingTop: "20px", width:"100%", textAlign: "center", position: "absolute", zIndex:9999, color: "white", marginTop: (props.item.videosrc ? "0" : 0)}}>
                <Typography style={{marginBottom: "10px", textShadow: "0 0 15px #333333"}} variant="h4">{props.item.name}</Typography>
                <Typography style={{textShadow: "0 0 10px #000000"}}>{props.item.description1}</Typography>
                <Typography style={{textShadow: "0 0 10px #000000"}}>{props.item.description2}</Typography>
                <Typography style={{textShadow: "0 0 10px #000000"}}>{props.item.description3}</Typography>
            </div>
            
        </Paper>
    )
}

export default ActivityCarousel