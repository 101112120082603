import { useIntl } from "react-intl";
import { useState } from "react";
import {
  LinearProgress,
  Box,
  TextField,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import { theme } from "../../theme";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import MapIcon from '@mui/icons-material/Map';
import LinkButton from "../LinkButton";
import { atTahko, } from "../../utils";
import "./header.css";

const companygrid = {
  color: "white",
  textAlign: "center" as const,
  backgroundColor: theme.palette.primary.dark,
  margin: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
};

type CategoryMap = {
  name: string,
  id: {[key: string] : number}
}

function CompaniesHeader(props : {setType: (type: string) => void, setSelectedCategory: (category: number) => void, setSearch: (search: string) => void, setFilterClosed: (filterClosed: boolean) => void, setOrderByDistance: (orderByDistance: boolean) => void
  type: string, selectedCategory: number, filterClosed: boolean, orderByDistance: boolean, isLoadingMore: boolean, categoriesMap: CategoryMap[]
}) {
  const { setType, setSelectedCategory, setSearch, setFilterClosed, setOrderByDistance, type, selectedCategory, filterClosed, orderByDistance, isLoadingMore } = props;
  const handleClickCategory = function (category: number) {
    setSelectedCategory(category);
  };

  const intl = useIntl();
  
  const handleSearchChange = (event: any) => {
    if (event.target.value !== "") {
      setSelectedCategory(0);
    }
    setSearch(event.target.value);
  };

  const handleType = (
    event: React.MouseEvent<HTMLElement>,
    newType: string,
  ) => {
    setType(newType);
  };



  return (
    <Grid style={{height: "230px"}} container>
      <Grid
        style={{ ...companygrid, margin: 0 }}
        item
        xs={12}
        sm={12}
        md={12}
      >
        {props.categoriesMap.map((Category: CategoryMap, index: number) => (
          <LinkButton
          size="small"
          key={index}
          selected={selectedCategory === (Category.id[intl.locale] ? Category.id[intl.locale] : Category.id["en"])}
          onClick={() => handleClickCategory(Category.id[intl.locale] ? Category.id[intl.locale] : Category.id["en"])}
          >
            {intl.formatMessage({ id: Category.name })}
          </LinkButton>
        ))}
      </Grid>
      <Grid
        style={{ ...companygrid, margin: 0, paddingTop: 0 }}
        item
        xs={12}
        sm={12}
        md={12}
      >
        <FormGroup>
          <TextField
            size="small"
            id="outlined-basic"
            onChange={handleSearchChange}
            label={intl.formatMessage({ id: "search" })}
            variant="filled"
            InputLabelProps={{ style: { color: "#fff" } }}
          />
          <Grid>
            <FormControlLabel
              label={intl.formatMessage({ id: "onlyopen" })}
              control={
                <Checkbox
                  style={{ color: "#fff" }}
                  checked={filterClosed}
                  onChange={() => setFilterClosed(!filterClosed)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
            {atTahko() && type !== "map" && (
              <FormControlLabel
                label={intl.formatMessage({ id: "orderbydistance" })}
                control={
                  <Checkbox
                    icon={<></>}
                    checkedIcon={<KeyboardArrowDownIcon/>}
                    style={{ color: "#fff" }}
                    checked={orderByDistance}
                    onChange={() => setOrderByDistance(!orderByDistance)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
              />
            )}
          </Grid>
          <Grid>
            <ToggleButtonGroup
              value={type}
              exclusive
              onChange={handleType}>
              <ToggleButton value="list" aria-label="left aligned">
                <FormatAlignCenterIcon style={{color: (type === "list" ? "white" : "black")}}/>
              </ToggleButton>
              <ToggleButton value="map" aria-label="centered">
                <MapIcon style={{color: (type === "map" ? "white" : "black")}}/>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </FormGroup>
      </Grid>
      {isLoadingMore ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress style={{ transition: "none" }} />
        </Box>
      ) : (
        ""
      )}
    </Grid>
  );
}

export default CompaniesHeader;
