import { Geolocation } from '@capacitor/geolocation';
import { useAppState } from "../../Appstate";

export const Init = () => {
  console.log("request location permisson");
  Geolocation.requestPermissions().then((permission) => {
    console.log("request location permisson granted", permission)
  })
   
}

export const SetLocation = () => {
  console.log("getlocation");
  if(process.env.REACT_APP_FORCE_TAHKO_LOCATION === "true"){
    useAppState.setState({coordinates: {coords: {latitude: 63.28994449330883, longitude: 28.039915215550298}}});
  } else {
    Geolocation.getCurrentPosition().then((coordinates) => {
      console.log("geolocation", coordinates)
      useAppState.setState({coordinates: coordinates});
    }).catch((error) => {
      console.log('Error getting location', error);
    });
  }
}


