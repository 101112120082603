import { Box, Grid } from "@mui/material";
import { ReactElement, useMemo, useState } from "react";
import ActivityCard from "./ActivityCard";
import { theme } from "../../theme";
import { useQuery } from "@apollo/client";
import { ACTIVITY_INFO } from "../../api/api";
import { useIntl } from "react-intl";
import LiveTv from "./tahko-icon-live.svg";
import WeatherIconWinter from "./tahko-icon-saa-talvi.svg";
import WeatherIconSummer from "./tahko-icon-saa-kesa.svg";
import SnowDepth from "./tahko-icon-lumimaara.svg";
import SlopesIcon from "./tahko-icon-avoimet-rinteet.svg";
import LiftIcon from "./tahko-icon-hisseja-avoinna.svg";
import SkiTrackIcon from "./tahko-icon-latuja.svg";
import SnowmobileIcon from "./tahko-icon-moottorikelkkareitit.svg";
import IceParkingIcon from "./tahko-icon-jaaparkki-ikoni.svg";
import SnowMaking from "./tahko-icon-lumetus.svg";
import BusIcon from "./tahko-icon-bussiyhteydet.svg";
import OpeningHours from "./tahko-icon-aukioloajat.svg";
import Map from "./tahko-icon-map.svg";
import GolfCourse from "./tahko-icon-golf.svg";
import PedalBike from "./tahko-icon-pyoraily.svg";
import DiscGolf from "./tahko-icon-frisbee.svg";
import Zipline from "./tahko-icon-zipline.svg";
import Hiking from "./tahko-icon-nuotio.svg";
import Kayaking from "./tahko-icon-vedessa.svg";
import Tahkolainen from "./tahko-icon-tahkolainen.svg";
import Stairs from "./tahko-icon-vaellus.svg";
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import { isSeason } from "../../utils";
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import { useAppState } from "../../Appstate";
import LoginIcon from '@mui/icons-material/Login';
import Avatar from '@mui/material/Avatar';
import ActivityCarousel from "./ActivityCarousel";
import ActivityReservations from "./ActivityReservations";
import { atTahko } from "../../utils";
import {
  GET_ACTIVE_RESERVATIONS
} from "../../api/api";

const halfBoxItem = {
  flex: 1
};

const halfBoxContainer = {
};

type ActivityInfo = {
  snow: string;
  golf_old: string;
  golf_new: string;
  iceparking: string;
  icewarning: string;
  snowing: string;
  temperature: string;
  temperature_updated: string;
  slopesOpen: number;
  slopeCount: number;
  liftsOpen: number;
  liftCount: number;
  skitracks: number;
  skitracks_open: number;
  snowmobileRouteLength: number;
};


function ActivityList(): ReactElement {
  const [activityInfo, setActivityInfo] = useState<ActivityInfo | null>();
  const [activeReservations, setActiveReservations] = useState<any | null>();
  const intl = useIntl();
  const tahkolainen = useAppState((state) => state.appState.customerInfo?.tahkolainen);
  const omistaja = useAppState((state) => state.appState.customerInfo?.omistaja);
  const loggedIn = useAppState((state) => state.appState.accessToken ? true : false);
  const firstName = useAppState((state) => state.appState.customerInfo?.firstName || "");
  const lastName = useAppState((state) => state.appState.customerInfo?.lastName || "");
  const customerId = useAppState((state) => state.appState.customerInfo?.id || "");

  useQuery(GET_ACTIVE_RESERVATIONS, {
    variables: {
      customerId: customerId
    },
    onCompleted: (data) => {
      setActiveReservations(data.www_activeReservations);
    },
  });

  console.log("activeReservations: ", activeReservations);

  useQuery(ACTIVITY_INFO, {
    onCompleted: (data) => {
      setActivityInfo(data.www_activityInfo);
    },
  });

  const winterActivities = useMemo(() => {
    return (
      <>
        <Grid item xs={6} sm={4} md={3}>
          <Grid
            spacing={theme.spacing(1.5)}
            direction="column"
            sx={halfBoxContainer}
            container
          >
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "snowDepth" })}
                metric={
                  activityInfo
                    ? `${Math.round(parseFloat(activityInfo?.snow || ""))}CM`
                    : undefined
                }
                icon={<img src={SnowDepth} alt=""/>}
                half={true}
              />
            </Grid>
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "snowmaking" })}
                metric={
                  activityInfo
                    ? activityInfo.snowing === "1"
                      ? intl.formatMessage({ id: "yes" })
                      : intl.formatMessage({ id: "no" })
                    : undefined
                }
                icon={<img src={SnowMaking} alt=""/>}
                half={true}
            />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Grid
            spacing={theme.spacing(1.5)}
            direction="column"
            sx={halfBoxContainer}
            container
          >
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "slopeInfo" })}
                metric={
                  activityInfo
                    ? `${activityInfo?.slopesOpen}/${activityInfo?.slopeCount}`
                    : undefined
                }
                link="/slopes"
                icon={<img src={SlopesIcon} alt=""/>}
                half={true}
              />
            </Grid>
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "lifts" })}
                metric={
                  activityInfo
                    ? `${activityInfo?.liftsOpen}/${activityInfo?.liftCount}`
                    : undefined
                }
                link="/lifts"
                icon={<img src={LiftIcon} alt=""/>}
                half={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Grid
            spacing={theme.spacing(1.5)}
            direction="column"
            sx={halfBoxContainer}
            container
          >
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "skiTracks" })}
                metric={activityInfo ? `${activityInfo?.skitracks_open}km` : undefined}
                link={
                  "https://www.tahko.com/nae-ja-koe/aktiviteetit/maastohiihto"
                }
                icon={<img src={SkiTrackIcon} alt=""/>}
                half={true}
              />
            </Grid>
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "snowmobileRoutes" })}
                metric={
                  activityInfo
                    ? `${activityInfo?.snowmobileRouteLength}km`
                    : undefined
                }
                link={
                  "https://www.tahko.com/nae-ja-koe/aktiviteetit/moottorikelkkailu"
                }
                icon={<img src={SnowmobileIcon} alt=""/>}
                half={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Grid
            spacing={theme.spacing(1.5)}
            direction="column"
            sx={halfBoxContainer}
            container
          >
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "iceParking" })}
                metric={
                  activityInfo
                    ? activityInfo.iceparking === "Auki"
                      ? intl.formatMessage({ id: "yes" })
                      : intl.formatMessage({ id: "no" })
                    : undefined
                }
                icon={<img src={IceParkingIcon} alt=""/>}
                half={true}
              />
            </Grid>
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "bus" })}
                metric={" "}
                link={
                  intl.locale === "fi" ?
                  "https://kuopio.digitransit.fi/haku?&lang=fi&address1=Kuopion%20matkakeskus,%20Kuopio&lon1=27.680268&lat1=62.897907&address2=Tahko,%20Kuopio&lon2=28.037458&lat2=63.291405" : 
                  "https://kuopio.digitransit.fi/haku?&lang=en&address1=Kuopion%20matkakeskus,%20Kuopio&lon1=27.680268&lat1=62.897907&address2=Tahko,%20Kuopio&lon2=28.037458&lat2=63.291405"
                  }
                icon={<img src={BusIcon} alt=""/>}
                half={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }, [activityInfo, intl]);

  const summerActivities = useMemo(() => {
    return (
      <>
        <Grid item xs={6} sm={4} md={3}>
          <Grid
            spacing={theme.spacing(1.5)}
            direction="column"
            sx={halfBoxContainer}
            container
          >
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "golfCourses" })}
                metric={
                  activityInfo
                    ? `${
                        parseInt(activityInfo?.golf_new) +
                        parseInt(activityInfo?.golf_old)
                      }/36`
                    : undefined
                }
                unit={intl.formatMessage({ id: "golfFairways" })}
                link={
                    intl.locale === "fi" ?
                      ("https://www.tahko.com/nae-ja-koe/aktiviteetit/golf/") :
                      ("https://www.tahko.com/en/things-to-do/activities/golf/")
                }
                icon={<img src={GolfCourse} alt=""/>}
                half={true}
              />
            </Grid>
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "tahkoStairs" })}
                metric={`1054`}
                unit={intl.formatMessage({ id: "steps" })}
                link={
                  intl.locale === "fi" ?
                    ("https://www.tahko.com/paikka/tahkon-portaat/") :
                    ("https://www.tahko.com/en/place/tahko-stairs/")
                }
                icon={<img src={Stairs} alt=""/>}
                half={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Grid
            spacing={theme.spacing(1.5)}
            direction="column"
            sx={halfBoxContainer}
            container
          >
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "bikeRoutes" })}
                metric={" "}
                link={
                  intl.locale === "fi" ?
                    ("https://www.tahko.com/nae-ja-koe/aktiviteetit/maastopyoraily/") :
                    ("https://www.tahko.com/en/things-to-do/biking/")
                }
                icon={<img src={PedalBike} alt=""/>}
                half={true}
              />
            </Grid>
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "discGolf" })}
                metric={`27/27`}
                unit={intl.formatMessage({ id: "golfFairways" })}            
                link={
                  intl.locale === "fi" ?
                    ("https://www.tahko.com/nae-ja-koe/aktiviteetit/frisbeegolf/") :
                    ("https://www.tahko.com/en/things-to-do/activities/disc-golf/")
                }
                icon={<img src={DiscGolf} alt=""/>}
                half={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Grid
            spacing={theme.spacing(1.5)}
            direction="column"
            sx={halfBoxContainer}
            container
          >
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "tahkoZipline" })}
                metric={"1000m"}
                link={
                    "https://mobile.shop.tahko.com/products/L21715"
                }
                icon={<img src={Zipline} alt=""/>}
                half={true}
              />
            </Grid>
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "hiking" })}
                metric={" "}
                link={
                  intl.locale === "fi" ?
                    ("https://www.tahko.com/nae-ja-koe/aktiviteetit/retkeily-ja-luontokohteet") :
                    ("https://www.tahko.com/en/things-to-do/activities/hiking-trails-and-natural-attractions/")
                }
                icon={<img src={Hiking} alt=""/>}
                half={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Grid
            spacing={theme.spacing(1.5)}
            direction="column"
            sx={halfBoxContainer}
            container
          >
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "waterActivities" })}
                metric={" "}
                link={
                  intl.locale === "fi" ?
                    ("https://www.tahko.com/nae-ja-koe/vesilla/") :
                    ("https://www.tahko.com/en/things-to-do/in-water/")
                }
                icon={<img src={Kayaking} alt=""/>}
                half={true}
              />
            </Grid>
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "bus" })}
                metric={" "}
                link={
                  intl.locale === "fi" ?
                  "https://kuopio.digitransit.fi/haku?&lang=fi&address1=Kuopion%20matkakeskus,%20Kuopio&lon1=27.680268&lat1=62.897907&address2=Tahko,%20Kuopio&lon2=28.037458&lat2=63.291405" : 
                  "https://kuopio.digitransit.fi/haku?&lang=en&address1=Kuopion%20matkakeskus,%20Kuopio&lon1=27.680268&lat1=62.897907&address2=Tahko,%20Kuopio&lon2=28.037458&lat2=63.291405"
                  }
                icon={<img src={BusIcon} alt=""/>}
                half={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }, [activityInfo, intl]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        height: "100vh",
        padding: theme.spacing(2),
      }}
    >
      <Grid container spacing={2}>
      <Grid item xs={12}>
        <ActivityCarousel></ActivityCarousel>
        
      </Grid>
      <Grid item xs={12}>
      {activeReservations?.length > 0 && <ActivityReservations activeReservations={activeReservations}></ActivityReservations>}
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "shop" })}
            metric={" "}
            //link={"https://shop.tahko.com"}
            link={atTahko() ? "/shop/services" : "/shop"}
            icon={<ShoppingCartCheckoutOutlinedIcon style={{"fontSize": "38px", stroke: "rgb(30, 45, 78)", strokeWidth: 1, "color": "#ddbc61"}}></ShoppingCartCheckoutOutlinedIcon>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "events" })}
            metric={" "}
            link={
              intl.locale === "fi" ?
                "/events" :
                "/events?langId=en"
            }
            icon={<LocalActivityOutlinedIcon style={{"fontSize": "38px", stroke: "rgb(30, 45, 78)", strokeWidth: 1, "color": "#ddbc61"}}/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "openinghours" })}
            metric={" "}
            link={
              intl.locale === "fi" ?
                "/companies?type=list" :
                "/companies?langId=en&type=list"
            }
            icon={<img src={OpeningHours} alt=""/>}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <ActivityCard
            title={intl.formatMessage({ id: "map" })}
            metric={" "}
            link={
              intl.locale === "fi" ?
                "/companies?type=map" :
                "/companies?langId=en&type=map"
            }
            icon={<img src={Map} alt=""/>}
          />
        </Grid>
        {tahkolainen == 2 &&
          <Grid sx={{display: "block"}} item xs={6} sm={4} md={3}>
            <ActivityCard
              title={intl.formatMessage({ id: "tahkolainen" })}
              metric={" "}
              link="/tahkolainen"
              icon={<img src={Tahkolainen} alt=""/>}
            />
          </Grid>
        }
        {(tahkolainen !== 2 && loggedIn === true) &&
          <Grid sx={{display: "block"}} item xs={6} sm={4} md={3}>
            <ActivityCard
              title={intl.formatMessage({ id: "myInfo" })}
              metric={" "}
              link="/reservations"
              icon={<Avatar style={{border: "1px solid #ddbc61", color: "#ddbc61", backgroundColor: "rgba(0,0,0,0)"}}>{firstName.charAt(0)}{lastName.charAt(0)}</Avatar>}
            />
          </Grid>
        }
        {(tahkolainen !== 2 && loggedIn === false) &&
          <Grid sx={{display: "block"}} item xs={6} sm={4} md={3}>
            <ActivityCard
              title={intl.formatMessage({ id: "login" })}
              metric={" "}
              link="/login"
              icon={<LoginIcon style={{"fontSize": "38px", stroke: "rgb(30, 45, 78)", strokeWidth: 1, "color": "#ddbc61"}}/>}
            />
          </Grid>
        }
        {omistaja == 1 &&
          <Grid sx={{display: "block"}} item xs={6} sm={4} md={3}>
            <ActivityCard
              title={intl.formatMessage({ id: "omistaja" })}
              metric={" "}
              link={process.env.REACT_APP_OMISTAJA_URL ? process.env.REACT_APP_OMISTAJA_URL : "https://omistajaliittyma.tahko.com"}
              icon={<img src={Tahkolainen} alt=""/>}
            />
          </Grid>
        }
        <Grid item xs={6} sm={4} md={3}>
          <Grid
            spacing={theme.spacing(1.5)}
            direction="column"
            sx={halfBoxContainer}
            container
          >
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "live" })}
                metric={" "}
                link="/live"
                icon={
                  <img src={LiveTv} alt=""/>
                }
                half={true}
              />
            </Grid>
            <Grid sx={halfBoxItem} item>
              <ActivityCard
                title={intl.formatMessage({ id: "weather" })}
                metric={
                  activityInfo
                    ? `${Math.round(parseFloat(activityInfo?.temperature || ""))}°C`
                    : undefined
                }
                link={"/weather"}
                icon={
                  isSeason("winter") ? (
                    <img src={WeatherIconWinter} alt=""/>
                  ) : (
                    <img src={WeatherIconSummer} alt=""/>
                  )
                }
                half={true}
              />
            </Grid>
          </Grid>
        </Grid>

        {isSeason("winter") && winterActivities}
        {isSeason("summer") && summerActivities}

        
      </Grid>
    </Box>
  );
}

export default ActivityList;
