import { useEffect } from 'react';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { useLazyQuery, gql } from "@apollo/client";
import semver from "semver"
import { useIntl } from "react-intl";

const APPVERSION = gql`
query appversion {
  www_appversion {
    minversion
    minbuild
  }
}`;

function Init() {
    const intl = useIntl();

    const [appVersionQuery] = useLazyQuery(APPVERSION, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
            console.log("minversion", data.www_appversion.minversion);
            App.getInfo().then((info) => {
                console.log("appinfo", info.version);
                console.log("minversion", data.www_appversion.minversion);
                if(semver.gt(data.www_appversion.minversion, info.version)){
                    alert(intl.formatMessage({
                        id: "updateApp",
                        defaultMessage: "Päivitä sovellus",
                      }));
                    if (Capacitor.getPlatform() === 'ios') {
                        window.location.href = "https://apps.apple.com/us/app/tahko/id1601204063";
                    } else if (Capacitor.getPlatform() === 'android') {
                        window.location.href = "https://play.google.com/store/apps/details?id=com.choicely.tahko";
                    }
                }
            });
        }
    });
    
    useEffect(() => {
        appVersionQuery();
    }, [window.location.href, appVersionQuery]);
    
    return null;
}

export default Init