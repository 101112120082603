import { create } from 'zustand'
import { Preferences } from '@capacitor/preferences';
import { App } from '@capacitor/app';

interface IAppState {
    init: boolean,
    initLogin: boolean,
    pushNotificationToken: string
    appState: any,
    coordinates: any,
    setAppState: (appState: any) => void,
    setInitLogin: () => void,
}

export const useAppState = create<IAppState>((set, get) => ({
    init: false,
    initLogin: true,
    appState: "",
    pushNotificationToken: "",
    coordinates: null,
    setAppState: (appState : any) => {
        Preferences.set({key: "appState", value: JSON.stringify(appState)}).then(() => {
            set(state => ({ ...state, appState: appState}))
        })
        return null;
    },
    setInitLogin: () => set(state => ({ ...state, initLogin: false})),
}))

Preferences.get({key: "appState"}).then((value) => {
    const parsed = JSON.parse(value.value ? value.value : "")
    console.log("initstate", parsed)
    useAppState.setState({appState: parsed, init: true});
})


// Set initLogin(App log in) when App state changes
App.addListener('appStateChange', ({ isActive }) => {
    if(isActive){
        useAppState.setState({initLogin: true})
    }
});

