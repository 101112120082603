import React, { useState, useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import { SafeArea } from 'capacitor-plugin-safe-area';
import LoginStatus from '../Login/LoginStatus';
import { useAppState } from "../../Appstate";
import logo from '../../assets/tahkoliehu.gif';

function Footer() {
    const [statusBarHeight, setStatusBarHeight] = useState(0);
    const loggedIn = useAppState((state) => state.appState.accessToken ? true : false);

    const fixedFooterContainer = {
        flexGrow: 1,
        display: "grid",
        placeItems: "center",
        position: "fixed",
        left: "0",
        width: "100%",
        height: "60px",
        backgroundColor: "rgb(21, 31, 54)",
        color: "white",
        textAlign: "center",
        paddingTop: "5px",
        paddingLeft: "10px",
        top: `${statusBarHeight}px`,
        zIndex: 10
      };
    
    const boxbelow = {
        height: (statusBarHeight + 60)
    };

    const safeArea = {
        top: 0,
        left: 0,
        position: "fixed",
        height: `${statusBarHeight}px`,
        width: "100%",
        backgroundColor: "rgb(21, 31, 54)",
        zIndex: 10
    };

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(loggedIn ? "/reservations" : "/login")
    };

    useEffect(() => {
        SafeArea.setImmersiveNavigationBar();

        SafeArea.getSafeAreaInsets().then(({ insets }) => {
            setStatusBarHeight(insets.top);
          });

          
        
    },[]);

    return (
        <>
        <Box >
            <Box sx={safeArea} position="static"></Box>
            <Box sx={fixedFooterContainer} position="static">
               
                    <div style={{width: "100%"}}>
                        <IconButton  
                            onClick={() => navigate("/")}
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            sx={{ display: "block", float:"left", mr: 2 }}
                        > 
                            <HomeIcon></HomeIcon>
                        </IconButton>
                        <IconButton
                            onClick={() => handleClick()}
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            sx={{ display: "none", float:"left", mr: 2 }}
                        > 
                            <MenuIcon></MenuIcon>
                        </IconButton>
                        <IconButton>
                            <img  style={{width: "76px" , height: "54px", marginLeft: "-25px", marginTop: "-11px"}} src={logo} alt="logo"/>
                        </IconButton>
                        <IconButton
                            size="large"
                            onClick={() => handleClick()}
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            sx={{float:"right" , mr: 2 }}
                        >
                            <LoginStatus></LoginStatus>
                        </IconButton>
                    </div>

            </Box>
        </Box>
        <Box sx={boxbelow}></Box>
        </>
    );
}


export default Footer;